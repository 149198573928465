@font-face {
  font-family: 'NunitoSans';
  src: url('./assets/fonts/NunitoSans-VariableFont_YTLC,opsz,wdth,wght.ttf')
    format('truetype-variations');
  font-weight: 1 999;
  font-stretch: 25% 151%;
}

body {
  margin: 0;
  font-family: var(--font-family-sans-serif) !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 1rem !important;
  color: #39557e !important;
  font-weight: 500 !important;
  overflow-y: auto;
  overflow-x: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.main-container {
  min-height: 100vh;
  position: relative;
  margin-bottom: 20px;
}

.container {
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

.container-header-seperator {
  width: 100%;
  margin-top: 50px;
}

.lang-select {
  display: flex;
  background-color: rgba(0, 0, 0, 0);
  transition: all 0.2s ease-out;
  border-radius: 50px;
  padding: 0.2em 0.9em;
  line-height: 24px;
  border-color: var(--outline);
  cursor: pointer;
}

.img-hm-banner {
  margin-right: -64px;
}

.hm-info-content {
  margin-top: 85px;
}

.hm-info-content h1 {
  font-size: 5.2rem;
}

.yh-text {
  color: var(--primary);
  position: relative;
}

.hm-info-content .hm-info {
  max-width: 85%;
  padding-top: 30px;
  line-height: 1.7;
}

.pattern-btm-line {
  position: absolute;
  left: 0;
  bottom: -40px;
}

.bg-pattern-1 {
  position: absolute;
  bottom: 490px;
  left: 0;
  z-index: -1;
}
.bg-pattern-2 {
  position: absolute;
  bottom: 50px;
  right: 110px;
  z-index: -1;
}
.bg-pattern-3 {
  position: absolute;
  bottom: -140px;
  right: 0;
  z-index: -1;
}
.ex-card {
  background-color: var(--white);
  border-radius: 20px !important;
  border: 1px solid var(--outline) !important;
  min-height: 400px;
  padding: 30px;
  box-shadow: none !important;
}

.ex-card-img .img-bg {
  width: 100%;
  height: 224px;
  border-radius: 20px;
  object-fit: cover;
}
.max-container {
  margin: 0px auto;
  max-width: 100rem;
  width: 100%;
}
.ex-title {
  font-size: 1.2rem;
  color: var(--gray-dark);
  padding: 20px 0;
  margin-bottom: 0;
    line-height: 2rem;
    overflow: hidden;
    display: block;
    /* max-height: 4.4rem; */
    min-height: 4.4rem;
    -webkit-line-clamp: 2;
    display: box;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    text-overflow: ellipsis;
    white-space: normal;
}
.fw-800 {
  font-weight: 800 !important;
}
.fw-600 {
  font-weight: 600 !important;
}
.mt--5 {
  margin-top: -5px;
}
.tag-item {
  border-radius: 20px;
  padding: 4px 15px;
  background-color: #e2f6ff;
}
.duration-item {
  border-radius: 20px;
  padding: 4px 15px;
  background-color: #e2ffe3;
}
.ex-info {
  overflow: hidden;
  max-height: 4.2rem;
  min-height: 4.2rem;
  -webkit-line-clamp: 3;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
  white-space: normal;
}
.author-img {
  width: 32px;
  height: 32px;
  border-radius: 50%;
  object-fit: cover;
}
.ex-price {
  font-size: 1.5rem;
  color: var(--gray-dark);
}
.ptb-100 {
  padding: 100px 0;
}
.ptb-50 {
  padding: 50px 0;
}
.pt-100 {
  padding-top: 100px;
}
.pb-100 {
  padding-bottom: 100px;
}
.mx-p-width {
  max-width: 700px;
}
.why-ex-card {
  background-color: #c3ffd2;
  border-radius: 20px;
  padding: 30px;
}
.why-exam-pattern {
  position: absolute;
  bottom: 0;
  left: -150px;
  z-index: -1;
  width: 100%;
  opacity: 0.7;
  height: 120vh;
}
.join-community {
  background-color: #061e43;
}
.vh-80 {
  height: 80vh;
}
.jn-com-pattern {
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: -1;
  width: 100%;
  height: 81vh;
}
.z-index-0 {
  z-index: 0;
}
.text-copy-right {
  margin-bottom: 0;
}
.footer-links-btm ul {
  list-style: none;
  margin: 0;
  padding: 0;
}
.footer-links-btm ul li {
  margin-right: 20px;
}
.footer-links-btm ul li:last-child {
  margin-right: 0;
}
.follow-us a {
  color: #39557e;
  font-size: 0.95rem;
}
.t2 {
  font-weight: 600;
  font-size: 1rem;
}
.footer-links {
  list-style: none;
  padding: 0;
  margin: 0;
}
.footer-links li {
  padding: 8px 0;
}
.footer-links li a {
  color: #39557e;
  font-size: 0.95rem;
}
.logo-brand-sign-up {
  display: flex;
  flex-flow: column;
  justify-content: start;
  align-items: start;
}
.logo-brand-sign-up svg {
  width: auto;
}
.sign-up-outer-area {
  height: 89vh;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-flow: column;
}
.sign-up-wrapper h1 {
  font-size: 2.6rem;
  margin-top: 30px;
}
.sign-up-bg {
  position: absolute;
  left: 0;
  top: 0;
  overflow: hidden;
  height: 100vh;
}
.form-card input {
  font-size: 0.9rem;
}
.sent-img-email{
  width: 50%;
}
.email-sent-section h4{
  font-size: 1.4rem;
}
.email-sent-section p{
  max-width: 60%;
  text-align: center;
}
.ex-card.in--my-learning{
  min-height: 590px;
  margin-bottom: 30px;
}
.user-name-t1 svg{
  width: 28px;
  margin-right: 10px;
}
.popular-exam-section .ex-title{
  max-height: 3.8rem;
  min-height: 2.4rem;
  -webkit-line-clamp: 1
}
.info-bg{
  background: #f7e1e1;
    padding: 1px 8px;
    border-radius: 30px 
}
@media (min-width: 768px) {
  .container {
    width: 750px;
  }
}

@media (min-width: 992px) {
  .container {
    width: 970px;
  }
}

@media (min-width: 1200px) {
  .container {
    width: 1170px;
  }
}
@media (max-width: 1800px) {
  .hm-info-content h1 {
    font-size: 4rem;
  }
}
@media (max-width: 1440px) {
  .hm-info-content h1 {
    font-size: 3.2rem;
  }
}
@media (max-width: 1220px) {
  .hm-info-content h1 {
    font-size: 2.7rem;
  }
  .navbar.px-lg-5 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }
  .navbar .navbar-nav .nav-item.nav-link {
    font-size: 0.9rem;
  }
  .navbar .nav-right-action .btn {
    font-size: 0.9rem;
  }
  .navbar .navbar-collapse.ml-5 {
    margin-left: 0 !important;
  }
  .navbar .navbar-brand {
    width: 159px;
  }
  .popular-exam-section {
    margin-top: 70px;
  }
}
@media (max-width: 1140px) {
  .btn-ex-buy-now .btn-primary.px-5 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }
  .ex-card,
  .why-ex-card {
    margin-bottom: 16px;
  }
  .why-exam-section .pt-100 {
    padding-top: 0;
  }
}
@media (max-width: 992px) {
  .hm-info-content h1 {
    font-size: 3.3rem;
    max-width: 470px;
    margin: 0 auto;
    margin-top: 40px;
  }
  .logo-brand-sign-up {
    justify-content: center;
    align-items: center;
    margin-bottom: 30px;
    text-align: center;
  }
  .bg-pattern-2,
  .bg-pattern-3,
  .img-hm-banner {
    display: none;
  }
  .sign-up-outer-area {
    align-items: center;
    justify-content: start;
}
.sign-up-outer-area .text-copy-right {
  margin-bottom: 30px !important;
}
.sign-up-outer-area .form-control{
  margin-bottom: 1rem;
}
.sign-up-outer-area .form-card{
  text-align: center;
}
.sign-in-link{
  justify-content: center !important;
}
.sign-up-bg{
  display: none;
}
.email-sent-section p{
  max-width: 100%;
  text-align: center;
}
  .hm-info-content .btn-primary {
    margin: 0 auto;
  }
  .hm-info-content .hm-info {
    max-width: 680px;
    margin: 0 auto;
    margin-top: 30px;
  }
  .footer-section .footer-brand {
    text-align: center;
  }
  .footer-section .footer-brand .follow-us .justify-content-start {
    justify-content: center !important;
    margin-bottom: 30px;
  }
  .footer-section .col-lg-8 .col-lg-4 {
    margin-bottom: 30px;
  }
  .copyright-block {
    flex-wrap: wrap;
    justify-content: center;
    flex-flow: column;
  }
  .copyright-block .footer-links-btm {
    margin-left: inherit !important;
    margin-top: 20px;
    font-size: 0.8rem;
  }
  .text-copy-right {
    font-size: 0.8rem;
  }
  .social-links {
    flex-wrap: wrap;
    justify-content: center;
  }
  .social-links a {
    margin-bottom: 10px;
  }
}
@media (max-width: 768px) {
  .bg-pattern-2,
  .bg-pattern-3,
  .img-hm-banner {
    display: none;
  }
  .popular-exam-section {
    margin-top: 100px;
  }
  .ex-card,
  .why-ex-card {
    margin-bottom: 16px;
  }
  .hm-info-content h1 {
    font-size: 3.3rem;
    max-width: 470px;
    margin: 0 auto;
    margin-top: 40px;
  }
  .hm-info-content .hm-info {
    max-width: 480px;
    margin: 0 auto;
    margin-top: 30px;
  }
  .hm-info-content .btn-primary {
    margin: 0 auto;
  }
  .hm-info-content .btn-primary.py-2 {
    padding-bottom: 0.25rem !important;
    padding-top: 0.25rem !important;
  }
  .why-exam-section .pt-100 {
    padding-top: 0;
  }
  .hm-info-content .row {
    margin: 0 !important;
  }
  .why-exam-pattern {
    left: 0;
  }
  h3,
  .h3,
  .ex-title {
    font-size: calc(1rem + 0.6vw);
  }
  .navbar .nav-right-action .btn {
    font-size: 0.8rem !important;
  }
  .navbar .nav-right-action .lang-select {
    font-size: 0.8rem !important;
  }
  .navbar-toggler-icon {
    width: 1em !important;
    height: 1em !important;
  }
  .nav-right-action {
    justify-content: space-between !important;
    margin: 0 auto;
}
.navbar {
  align-items: baseline !important;
  justify-content: space-between;
}
  .navbar .navbar-toggler {
    padding: 0.25rem 0.35rem;
    position: absolute;
    right: 16px;
    top: 20px;
  }
  .navbar {
    justify-content: center !important;
    flex-flow: column;
  }
  .ex-card {
    padding: 16px;
  }
  .vh-80 {
    height: 60vh;
  }
  .footer-brand-info {
    padding: 0 30px;
  }
}
@media (max-width: 440px) {
  .hm-info-content h1 {
    font-size: 2.7rem;
    margin: 0 auto;
    margin-top: 80px;
  }
  .pattern-btm-line {
    position: absolute;
    left: 0;
    bottom: -21px;
  }
  .hm-info-content .hm-info {
    max-width: 480px;
    margin: 0 auto;
    margin-top: 10px;
  }
  .navbar.px-lg-5 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  .nav-right-action {
    flex-wrap: wrap;
    justify-content: space-between;
  }
}
.m-auto.mbt-5{
  margin-bottom: 100px !important;
  margin-top: 50px !important;
}
.mbt-5{
  margin-bottom: 100px !important;
  margin-top: 50px !important;
}